import React, { useEffect, useState } from 'react';
import { getRequest } from '../UrlRequest';

const Health = ({ patientId, onDate, application = 'dialysis' }) => {
  const [feedback, setFeedback] = useState(null);
  const [goals, setGoals] = useState([]);
  const [loadingFeedback, setLoadingFeedback] = useState(true);
  const [loadingGoals, setLoadingGoals] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!patientId || !onDate) return;
    fetchFeedback();
    fetchGoals();
  }, [patientId, onDate, application]);

  const fetchFeedback = async () => {
    setLoadingFeedback(true);
    setError(null);
    try {
      const token = localStorage.getItem('accessToken');
      const data = await getRequest(application, `/doctor/${patientId}/feedback/daily?on-date=${onDate}`, token);
      setFeedback(data);
    } catch (error) {
      setError('피드백 데이터를 불러오는 중 오류가 발생했습니다.');
    } finally {
      setLoadingFeedback(false);
    }
  };

  const fetchGoals = async () => {
    setLoadingGoals(true);
    setError(null);
    try {
      const token = localStorage.getItem('accessToken');
      const data = await getRequest(application, `/doctor/${patientId}/goal/daily?on-date=${onDate}`, token);
      setGoals(data);
    } catch (error) {
      setError('목표 데이터를 불러오는 중 오류가 발생했습니다.');
    } finally {
      setLoadingGoals(false);
    }
  };

  return (
    <div className="health-container">
      <h2>건강 정보</h2>

      {/* Feedback Section */}
      <div className="feedback-section">
        <h3>피드백</h3>
        {loadingFeedback ? (
          <p>피드백을 불러오는 중...</p>
        ) : error ? (
          <p>{error}</p>
        ) : feedback ? (
          <div className="feedback-content">
            <p><strong>요약:</strong> {feedback.summary}</p>
            <p><strong>날짜:</strong> {feedback.on_date}</p>
            <p><strong>기간:</strong> {feedback.period}일</p>
          </div>
        ) : (
          <p>피드백 데이터가 없습니다.</p>
        )}
      </div>

      {/* Goals Section */}
      <div className="goals-section">
        <h3>목표</h3>
        {loadingGoals ? (
          <p>목표를 불러오는 중...</p>
        ) : error ? (
          <p>{error}</p>
        ) : goals.length > 0 ? (
          goals.map((goal) => (
            <div key={goal.id} className="goal-item">
              <p><strong>태그:</strong> {goal.tag}</p>
              <p><strong>내용:</strong> {goal.content}</p>
              <p><strong>사유:</strong> {goal.reason}</p>
              <p><strong>상태:</strong> {goal.status ? '완료' : '미완료'}</p>
              <p><strong>기간:</strong> {goal.period}일</p>
              <hr />
            </div>
          ))
        ) : (
          <p>목표 데이터가 없습니다.</p>
        )}
      </div>
    </div>
  );
};

export default Health;
