// Chart.js
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { format, parseISO } from 'date-fns';
import React, { useMemo, useRef, useEffect } from 'react';

ChartJS.register(...registerables);

const Chart = ({ labels, data, data2, label, label2, dummyData }) => {
  const canvasRef = useRef(null); // canvas 요소 참조
  const chartInstance = useRef(null); // 차트 인스턴스 참조
  const formattedLabels = labels.map((date) => format(parseISO(date), 'MM-dd'));

  useEffect(() => {
    // 기존 차트 인스턴스가 있을 경우 destroy
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    // 데이터 수에 따라 점 크기 설정 (데이터 수가 많으면 점 크기 축소)
    const pointRadius = data.length > 30 ? 0 : 5;

    // 데이터의 min과 max 값을 계산
    const allData = [...data, ...(data2 || [])];
    const minValue = Math.min(...allData);
    const maxValue = Math.max(...allData);

    // 데이터 수가 30개 초과일 때만 y축 범위 설정
    const yAxisOptions = data.length > 30 
      ? { min: minValue - 20, max: maxValue + 20 } 
      : {};

    // 새 차트 인스턴스 생성
    chartInstance.current = new ChartJS(canvasRef.current, {
      type: 'line',
      data: {
        labels: formattedLabels,
        datasets: [
          {
            label,
            data,
            fill: false,
            borderColor: 'rgba(75,192,192,1)',
            tension: 0.1,
            borderWidth: 1,
            pointBackgroundColor: 'rgba(75,192,192,1)',
            pointRadius: data.map((_, index) => (dummyData[index] ? 0 : pointRadius)), // dummyData에 따라 점 크기 조정
          },
          ...(data2
            ? [
                {
                  label: label2,
                  data: data2,
                  fill: false,
                  borderColor: 'rgba(255,99,132,1)',
                  tension: 0.1,
                  borderWidth: 1,
                  pointBackgroundColor: 'rgba(255,99,132,1)',
                  pointRadius: data2.map((_, index) => (dummyData[index] ? 0 : pointRadius)),
                },
              ]
            : []),
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            callbacks: {
              label: (context) => `${context.dataset.label}: ${context.raw}`,
            },
          },
        },
        scales: {
          x: {
            type: 'category',
            title: {
              display: true,
              font: { size: 16 },
            },
            ticks: {
              maxRotation: 45,
              minRotation: 0,
            },
          },
          y: {
            title: {
              display: true,
              text: '측정값',
              font: { size: 16 },
            },
            ...yAxisOptions, // 데이터 수에 따라 min/max 적용
          },
        },
      },
    });

    // 컴포넌트가 unmount될 때 차트 인스턴스 제거
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [labels, data, data2, dummyData]);

  const chartWidth = useMemo(() => {
    const labelCount = labels.length;
    return labelCount > 29 ? '100%' : labelCount * 50;
  }, [labels]);

  return (
    <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
      <div style={{ width: chartWidth, height: '380px' }}>
        <canvas ref={canvasRef}></canvas> {/* canvas 요소에 참조 연결 */}
      </div>
    </div>
  );
};

export default Chart;
