import React, { useState } from 'react';
import { getRequest, postRequest } from '../UrlRequest'; // 수정된 요청 함수 사용

const Hospital = () => {
  const [searchParams, setSearchParams] = useState({ name: '', address: '' });
  const [hospitalList, setHospitalList] = useState([]);
  const [selectedHospitalId, setSelectedHospitalId] = useState(null);

  // 병원 검색 (GET 요청)
  const searchHospitals = async () => {
    try {
      const token = localStorage.getItem('accessToken'); // 토큰 가져오기
      const endpoint = `/hospitals/search?name=${searchParams.name}&address=${searchParams.address}`;
      const data = await getRequest('dialysis', endpoint, token); // 동적 GET 요청
      setHospitalList(data.hospitals);
    } catch (error) {
      console.error('Error fetching hospitals:', error);
    }
  };

  // 병원 등록/수정 (POST 요청)
  const registerHospital = async () => {
    if (!selectedHospitalId) {
      alert('병원을 선택해주세요.');
      return;
    }
    try {
      const token = localStorage.getItem('accessToken'); // 토큰 가져오기
      const endpoint = '/user/hospital';
      const requestBody = { hospital_id: selectedHospitalId };
      await postRequest('dialysis', endpoint, requestBody, token); // 동적 POST 요청
      alert('병원이 성공적으로 등록/수정되었습니다.');
    } catch (error) {
      console.error('Error registering hospital:', error);
      alert('병원 등록/수정에 실패했습니다.');
    }
  };

  // 검색 필드 업데이트
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchParams((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div style={styles.container}>
      <div style={styles.leftSection}>
        <h1 style={styles.title}>병원 검색</h1>
        <div style={styles.searchBox}>
          <label style={styles.label}>
            병원 이름:
            <input
              type="text"
              name="name"
              value={searchParams.name}
              onChange={handleInputChange}
              style={styles.input}
            />
          </label>
          <label style={styles.label}>
            병원 주소:
            <input
              type="text"
              name="address"
              value={searchParams.address}
              onChange={handleInputChange}
              style={styles.input}
            />
          </label>
          <button onClick={searchHospitals} style={styles.button}>
            검색
          </button>
        </div>
        <button onClick={registerHospital} style={styles.registerButton}>
          병원 등록/수정
        </button>
      </div>

      <div style={styles.rightSection}>
        <h2 style={styles.subtitle}>검색 결과</h2>
        <ul style={styles.list}>
          {hospitalList.map((hospital) => (
            <li key={hospital.id} style={styles.listItem}>
              <input
                type="radio"
                name="selectedHospital"
                value={hospital.id}
                onChange={() => setSelectedHospitalId(hospital.id)}
                style={styles.radio}
              />
              {hospital.name} - {hospital.address}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '20px',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    backgroundColor: '#f9fafb',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    maxWidth: '1000px',
    margin: '20px auto',
  },
  leftSection: {
    flex: '1',
    paddingRight: '20px',
    borderRight: '1px solid #ddd',
  },
  rightSection: {
    flex: '1',
    paddingLeft: '20px',
    height: '400px', // 스크롤 제한을 위한 고정 높이
    overflowY: 'auto', // 세로 스크롤 활성화
    backgroundColor: '#fff', // 스크롤 영역 배경색
    borderRadius: '8px', // 테두리 반경
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // 시각적 강조
    padding: '10px', // 내부 여백
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '20px',
  },
  label: {
    fontSize: '16px',
    fontWeight: '500',
  },
  input: {
    width: '100%',
    padding: '8px',
    fontSize: '14px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginTop: '5px',
  },
  button: {
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  registerButton: {
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '10px',
  },
  subtitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  list: {
    listStyle: 'none',
    padding: '0',
    margin: '0',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#f9fafb',
    borderBottom: '1px solid #ccc',
    borderRadius: '4px',
    marginBottom: '5px',
  },
  radio: {
    marginRight: '10px',
  },
};


export default Hospital;
