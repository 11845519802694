import React, { useState } from 'react';
import Diet from './Diet';
import Health from './Health';
import Hospital from './Hospital';

const Tabs = ({ patientId, selectedDate, deviceStatusId }) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    { label: '병원 등록/수정', component: <Hospital />, scrollable: false },
    { label: '식단', component: <Diet patientId={patientId} selectedDate={selectedDate} />, scrollable: true },
    { label: '목표와 피드백', component: <Health patientId={patientId} onDate={selectedDate} />, scrollable: true },
  ];
const styles = {
  tabContainer: {
    height: '80%',
  },
  tabButtons: {
    display: 'flex',
    marginBottom: '10px',
  },
  tabButton: {
    flex: '1',
    padding: '10px',
    backgroundColor: '#007bff',
    border: 'none',
    cursor: 'pointer',
  },
  activeTab: {
    backgroundColor: '#4B54DC ',
    color: '#fff',
    border: 'none',
  },
  tabContent: {
    height: '100%',
    overflowY: 'hidden', // 기본적으로 스크롤 비활성화
  },
  scrollableContent: {
    height: '450px',
    overflowY: 'auto', // 특정 탭에서만 스크롤 활성화
  },
};

  return (
    <div style={styles.tabContainer}>
      <div style={styles.tabButtons}>
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(index)}
            style={{
              ...styles.tabButton,
              ...(activeTab === index ? styles.activeTab : {}),
            }}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div
        style={
          tabs[activeTab].scrollable
            ? styles.scrollableContent
            : styles.tabContent
        }
      >
        {tabs[activeTab].component}
      </div>
    </div>
  );
};

export default Tabs;
