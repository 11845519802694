import React, { useEffect, useState, useCallback, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { getRequest } from '../UrlRequest';
import { useLocation } from 'react-router-dom';

const PatientGrid = ({ patientId, setSelectedDate, onRowClick }) => {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [pageSize] = useState(15);
  const [selectedRow, setSelectedRow] = useState(null);

  const location = useLocation();
  const application = location.state?.application || 'dialysis';
  const previousPatientId = useRef(null); 
  const gridRef = useRef(null);
  const [initialLoad, setInitialLoad] = useState(false);

  const fetchData = useCallback(async (currentPage, currentPatientId) => {
    if (loading || !hasMore || (initialLoad && currentPage > 1)) return;
    setLoading(true);

    try {
      const token = localStorage.getItem('accessToken');
      const data = await getRequest(application, `/doctor/health/${currentPatientId}?page=${currentPage}&size=${pageSize}`, token);
  
      const healthLogList = data.health_log_list || [];
      const formattedData = healthLogList.map((record) => ({
        on_date: record.on_date,
        systolic: record.blood_pressure?.systolic ?? '미등록',
        diastolic: record.blood_pressure?.diastolic ?? '미등록',
        weight: record.weight ?? '미등록',
        phosphorus: record.nutrition?.phosphorus ?? '미등록',
        potassium: record.nutrition?.potassium ?? '미등록',
        protein: record.nutrition?.protein ?? '미등록',
        sodium: record.nutrition?.sodium ?? '미등록',
      }));
  
      if (currentPatientId === previousPatientId.current) {
        setRowData((prevData) => [...prevData, ...formattedData]);
        setHasMore(healthLogList.length === pageSize);
      }
      setInitialLoad(false);
    } catch (error) {
      setError('데이터를 불러오는 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  }, [application, pageSize, loading, hasMore, initialLoad]);

  useEffect(() => {
    if (previousPatientId.current !== patientId) {
      setRowData([]);
      setPage(1);
      setHasMore(true);
      setInitialLoad(true);

      previousPatientId.current = patientId;

      console.log('patientId가 변경되었습니다. 데이터 초기화 중...');
      console.log('초기 페이지 설정: 1');
      console.log('새로운 patientId:', patientId);
      fetchData(1, patientId); 
    }
  }, [patientId, fetchData]);

  //fetchdata 두번 됨.
  useEffect(() => {
    if (initialLoad) {
      fetchData(1, patientId); // 첫 로드 시 페이지 1로 데이터 로드 //저건 3번째 로드시에 사용
    }
  }, [fetchData, initialLoad, patientId]);

  const loadNextPage = useCallback(() => {
    if (hasMore && !loading) {
      const nextPage = page + 1;
      fetchData(nextPage, patientId); 
      setPage(nextPage);
    }
  }, [hasMore, loading, fetchData, page, patientId]);

  const onScroll = useCallback(() => {
    const gridBody = gridRef.current?.querySelector('.ag-body-viewport');
    if (gridBody && gridBody.scrollTop + gridBody.clientHeight >= gridBody.scrollHeight - 5) {
      loadNextPage(); 
    }
  }, [loadNextPage]);

  useEffect(() => {
    const gridBody = gridRef.current?.querySelector('.ag-body-viewport');
    if (gridBody) {
      gridBody.addEventListener('scroll', onScroll);
      return () => gridBody.removeEventListener('scroll', onScroll);
    }
  }, [onScroll]);

  const columnDefs = [
    { headerName: '날짜', field: 'on_date', sortable: true, filter: true, flex: 1, minWidth: 100 },
    { 
      headerName: '수축기 혈압', 
      field: 'systolic', 
      flex: 1, 
      minWidth: 100,
      valueGetter: (params) => params.data.systolic ?? '미등록'
    },
    { 
      headerName: '이완기 혈압', 
      field: 'diastolic', 
      flex: 1, 
      minWidth: 100,
      valueGetter: (params) => params.data.diastolic ?? '미등록'
    },
    { 
      headerName: '체중', 
      field: 'weight', 
      flex: 1, 
      minWidth: 100, 
      valueFormatter: (params) => params.value !== '미등록' ? Number(params.value).toFixed(2) : params.value 
    },
    { 
      headerName: '인(mg)', 
      field: 'phosphorus', 
      flex: 1, 
      minWidth: 100, 
      valueFormatter: (params) => params.value !== '미등록' ? Number(params.value).toFixed(2) : params.value 
    },
    { 
      headerName: '칼륨(mg)', 
      field: 'potassium', 
      flex: 1, 
      minWidth: 100, 
      valueFormatter: (params) => params.value !== '미등록' ? Number(params.value).toFixed(2) : params.value 
    },
    { 
      headerName: '단백질(g)', 
      field: 'protein', 
      flex: 1, 
      minWidth: 100, 
      valueFormatter: (params) => params.value !== '미등록' ? Number(params.value).toFixed(2) : params.value 
    },
    { 
      headerName: '나트륨(mg)', 
      field: 'sodium', 
      flex: 1, 
      minWidth: 100, 
      valueFormatter: (params) => params.value !== '미등록' ? Number(params.value).toFixed(2) : params.value 
    },
  ];

  const handleRowClick = (event) => {
    const date = event.data.on_date;
    setSelectedDate(date);
    onRowClick(patientId, date);
    setSelectedRow(event.data);
  };

  return (
    <div
      className="patientgrid-content ag-theme-alpine"
      style={{ height: '500px', overflowY: 'auto' }}
      ref={gridRef}
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        pagination={false}
        onCellClicked={handleRowClick}
        getRowStyle={(params) => {
          if (selectedRow && params.data.on_date === selectedRow.on_date) {
            return { backgroundColor: '#d3f9d8' };
          }
          return null;
        }}
        gridOptions={{
          alwaysShowVerticalScroll: true,
          suppressScrollOnNewData: true,
        }}
      />
      {loading}
      {error && <p>{error}</p>}
    </div>
  );
};

export default PatientGrid;
