import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
//import Pulse from '../components/rightpannel/Pulse';
import Weight from '../components/rightpannel/Weight';
import BloodPressure from '../components/rightpannel/BloodPressure';
import PatientInfo from '../components/rightpannel/PatientInfo';
import PatientGrid from '../components/rightpannel/PatientGrid';
import Tabs from '../components/rightpannel/Tabs';
import Diet from '../components/rightpannel/Diet';
//import Chat from '../components/rightpannel/Chat';

import './DashboardPage.css';

const DashboardPage = () => {
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [selectedPatientInfo, setSelectedPatientInfo] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(7);
  const [deviceStatusId, setDeviceStatusId] = useState(null);

  const handleRowClick = (patientId, onDate) => {
    setSelectedPatientId(patientId);
    setSelectedDate(onDate);
  };

  // PatientInfo에서 호출할 함수로, 선택한 기간을 설정합니다.
  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  };

  return (
    <div className="dashboard-container">
      <div className="grid-container">
        <div className="dashboard">
          <div className="sidebar">
            <Sidebar 
              setSelectedPatientId={setSelectedPatientId} 
              setSelectedPatientInfo={setSelectedPatientInfo}
              setSelectedDeviceStatusId={setDeviceStatusId}
            />
          </div>

          <div className="patientinfo">
            <PatientInfo 
              patient={selectedPatientInfo} 
              onPeriodChange={handlePeriodChange} // 기간 변경 함수 전달
              selectedPeriod={selectedPeriod}
            />
          </div>

          {selectedPatientId && (
            <div className="patientgrid">
              <PatientGrid 
                patientId={selectedPatientId}
                setSelectedDate={setSelectedDate}
                setSelectedPeriod={setSelectedPeriod}
                onRowClick={handleRowClick}
              />
            </div>
          )}

          {/* 환자 ID 여부와 상관없이 Tabs 컴포넌트 렌더링 */}
          <div className="devicestatus">
            <Tabs patientId={selectedPatientId} selectedDate={selectedDate} deviceStatusId={deviceStatusId} />
          </div>
        </div>
      </div>

      <div className="stats-container">
        <div className="weight">
          <Weight patientId={selectedPatientId} selectedDate={selectedDate} selectedPeriod={selectedPeriod} />
        </div>

        <div className="bloodpressure">
          <BloodPressure patientId={selectedPatientId} selectedDate={selectedDate} selectedPeriod={selectedPeriod} />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
