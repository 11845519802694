// UrlRequest.js
import axios from 'axios';
import { handleTokenExpiry } from './Refresh';

const BASE_URL = {
  //dialysis: 'https://dialysis.livinai.xyz/api',
  dialysis: 'https://dialysis.livincare.kr/api',
  
  /*
  hearton: 'https://hearton.livinai.xyz/api/',


  */
};

// 동적 URL 생성 함수
export const getBaseUrl = (application) => {
  return BASE_URL[application] || 'https://dialysis.livincare.kr/api/';
};
// 토큰도 필요한 API 요청 함수 (Refresh.js에서 사용)
export const loginRequest = async (endpoint, data) => {
  try {
    const url = `https://sso.livincare.kr${endpoint}`; // 로그인 전용 URL
    const response = await axios.post(url, data, {
      headers: { 'Content-Type': 'application/json' },
    });

    console.log(`response: ${JSON.stringify(response)}`)
    // accessToken과 refreshToken 모두가 응답에 포함되었는지 확인
    if (response.data && response.data.accessToken && response.data.refreshToken) {
      return response.data; // { accessToken, refreshToken } 형태로 반환
    } else {
      throw new Error('토큰 응답이 올바르지 않습니다.');
    }
  } catch (error) {
    console.error('Login request error:', error);
    throw error;
  }
};
//토큰 필요없는 api 요청 함수.
export const ssoRequest = async (endpoint, data) => {
  try {
    const url = `https://sso.livincare.kr${endpoint}`; //토큰 검사 안하는 함수용임.
    const response = await axios.post(url, data, {
      headers: { 'Content-Type': 'application/json' },
    });

    console.log(`response: ${JSON.stringify(response)}`);
    // 응답 데이터 그대로 반환
    return response.data;
  } catch (error) {
    console.error('Login request error:', error);
    throw error;
  }
};
// GET 요청 함수
export const getRequest = async (application, endpoint, token = null) => {
  const headers = { 'Content-Type': 'application/json' };
  if (token) headers['Authorization'] = `Bearer ${token}`;

  const url = `${getBaseUrl(application)}${endpoint}`; // url 변수를 명확하게 정의
  try {
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // originalRequest 객체에 명확하게 url 추가
      
      const originalRequest = {
        method: 'get',
        url, // 명시적으로 url 설정
        headers,
      };
      return handleTokenExpiry(originalRequest);
    }
    console.error('GET request error:', error);
    throw error;
  }
};

// POST 요청 함수
export const postRequest = async (application, endpoint, data, token = null) => {
  const headers = { 'Content-Type': 'application/json' };
  if (token) headers['Authorization'] = `Bearer ${token}`;

  const url = `${getBaseUrl(application)}${endpoint}`;
  try {
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const originalRequest = {
        method: 'post',
        url,
        headers,
        data,
      };
      return handleTokenExpiry(originalRequest);
    }
    console.error('POST request error:', error);
    throw error;
  }
};

// PUT 요청 함수
export const putRequest = async (application, endpoint, data, token = null) => {
  const headers = { 'Content-Type': 'application/json' };
  if (token) headers['Authorization'] = `Bearer ${token}`;

  const url = `${getBaseUrl(application)}${endpoint}`;
  try {
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.log('토큰 만료, 재시도 중...');
      const originalRequest = {
        method: 'put',
        url,
        headers,
        data,
      };
      return handleTokenExpiry(originalRequest);
    }
    console.error('PUT request error:', error);
    throw error;
  }
};

// DELETE 요청 함수
export const deleteRequest = async (application, endpoint, token = null) => {
  const headers = { 'Content-Type': 'application/json' };
  if (token) headers['Authorization'] = `Bearer ${token}`;

  const url = `${getBaseUrl(application)}${endpoint}`;
  try {
    const response = await axios.delete(url, { headers });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.log('토큰 만료, 재시도 중...');
      const originalRequest = {
        method: 'delete',
        url,
        headers,
      };
      return handleTokenExpiry(originalRequest);
    }
    console.error('DELETE request error:', error);
    throw error;
  }
};

/*
// UrlRequest.js
import axios from 'axios';

// 기본 URL 설정
const BASE_URL = 'http://172.30.1.125:8001';

// GET 요청을 위한 함수
export const getRequest = async (endpoint, token) => {
  try {
    const response = await axios.get(`${BASE_URL}${endpoint}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('GET request error:', error);
    throw error;
  }
};

// POST 요청을 위한 함수
export const postRequest = async (endpoint, data, token) => {
  try {
    const response = await axios.post(`${BASE_URL}${endpoint}`, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('POST request error:', error);
    throw error;
  }
};

// PUT 요청을 위한 함수
export const putRequest = async (endpoint, data, token) => {
  try {
    const response = await axios.put(`${BASE_URL}${endpoint}`, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('PUT request error:', error);
    throw error;
  }
};

// DELETE 요청을 위한 함수
export const deleteRequest = async (endpoint, token) => {
  try {
    const response = await axios.delete(`${BASE_URL}${endpoint}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('DELETE request error:', error);
    throw error;
  }
};
*/


/*
// UrlRequest.js
import axios from 'axios';

// 기본 URL 설정
const BASE_URL = 'http://172.30.1.125:8001';

// GET 요청을 위한 함수
export const getRequest = async (endpoint, token) => {
  try {
    const response = await axios.get(`${BASE_URL}${endpoint}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('GET request error:', error);
    throw error;
  }
};

// POST 요청을 위한 함수
export const postRequest = async (endpoint, data, token) => {
  try {
    const response = await axios.post(`${BASE_URL}${endpoint}`, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('POST request error:', error);
    throw error;
  }
};

// PUT 요청을 위한 함수
export const putRequest = async (endpoint, data, token) => {
  try {
    const response = await axios.put(`${BASE_URL}${endpoint}`, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('PUT request error:', error);
    throw error;
  }
};

// DELETE 요청을 위한 함수
export const deleteRequest = async (endpoint, token) => {
  try {
    const response = await axios.delete(`${BASE_URL}${endpoint}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('DELETE request error:', error);
    throw error;
  }
};
*/
